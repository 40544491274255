import Icon from "@/common/components/Icon";
import { isUserExperimenting } from "@/common/utils";
import {
  googleAddToCart,
  googleRemoveFromCart,
  productToGoogleProduct,
} from "@/common/utils/analytics.utils";
import { sandCategory, sandFor2PlusCatsID } from "@/config";
import { update } from "@/redux/reducers/Boxes";
import { getBoxes, updateBox } from "@/services/boxes.service";
import { theme } from "@/theme";
import { moderateScale } from "@/theme/dimensions";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, View } from "react-native";
import { ActivityIndicator, Checkbox, Text } from "react-native-paper";
import { ToastType } from "react-native-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import Products from "./components/Products";
import Promocode from "./components/Promocode";
import Summary from "./components/Summary";
import styles from "./styles";
const SummaryStage = ({ toast }: { toast: ToastType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const animals = useSelector((state: RootState) => state.animals);
  const boxes = useSelector((state: RootState) => state.boxes);
  const subscription = useSelector((state: RootState) => state.subscription);
  const deliveries = useSelector((state: RootState) => state.deliveries);
  const experimental = isUserExperimenting(boxes, subscription, deliveries);
  const [wantsSand, setWantsSand] = useState<boolean>(false);
  const [changingSand, setChangingSand] = useState<boolean>(false);
  const removeSandFromBox = () => {
    setChangingSand(true);
    let new_box = { ...boxes[0] };
    let new_products = [];
    if (!new_box.products) return;
    for (let index = 0; index < new_box.products.length; index++) {
      if (new_box.products[index].product.category._id !== sandCategory) {
        new_products.push({ ...new_box.products[index] });
      }
    }
    new_box.products = new_products;
    updateBox(user.accessToken, new_box.animal, new_box, sandFor2PlusCatsID)
      .then(async (response) => {
        await googleRemoveFromCart({
          currency: "EUR",
          value: new_box.products[new_box.products.length - 1].product.price,
          items: productToGoogleProduct(
            new_box.products[new_box.products.length - 1],
          ),
        });
        dispatch(update(response.data.box));
        getBoxes(user.accessToken)
          .then((response2) => {
            setChangingSand(false);
          })
          .catch((error) => {
            toast.show(t("BackendNotification" + error.response.data.message), {
              type: "danger",
            });
            setChangingSand(false);
          });
      })
      .catch((error) => {
        toast.show(t("BackendNotification" + error.response.data.message), {
          type: "danger",
        });
        setChangingSand(false);
      });
  };

  const addSandToBox = () => {
    setChangingSand(true);
    let new_box = { ...boxes[0] };
    new_box.products = [...new_box.products];
    new_box.products.push({
      product: sandFor2PlusCatsID as any, // VanCat Carvao Ativado 8.5kg
      quantity: 1,
    });
    updateBox(user.accessToken, new_box.animal, new_box, sandFor2PlusCatsID)
      .then((response) => {
        dispatch(update(response.data.box));
        getBoxes(user.accessToken)
          .then(async (response2) => {
            setChangingSand(false);
            await googleAddToCart({
              currency: "EUR",
              value:
                response.data.box.products[
                  response.data.box.products.length - 1
                ].product.price,
              items: productToGoogleProduct(
                response.data.box.products[
                  response.data.box.products.length - 1
                ],
              ),
            });
          })
          .catch((error) => {
            toast.show(t("BackendNotification" + error.response.data.message), {
              type: "danger",
            });
            setChangingSand(false);
          });
      })
      .catch((error) => {
        toast.show(t("BackendNotification" + error.response.data.message), {
          type: "danger",
        });
        setChangingSand(false);
      });
  };

  const onPressToChangeSand = () => {
    if (wantsSand) {
      removeSandFromBox();
    } else {
      addSandToBox();
    }
    setWantsSand(!wantsSand);
  };
  return (
    <View style={styles.container}>
      <Summary />
      {!boxes.some((b) => b.products.some((bp) => !!bp.product.food)) && (
        <Promocode toast={toast} />
      )}
      <View style={[styles.sandInputContainer]}>
        {changingSand ? (
          <ActivityIndicator
            animating={changingSand}
            color={theme.colors.secondary}
          />
        ) : (
          <>
            <Image
              style={styles.image}
              source={{
                uri: "https://duxnatura-storage.s3.eu-west-1.amazonaws.com/VanCatAreiaComCarvao10L_main.webp",
              }}
            />
            <View style={styles.sandInput}>
              <Checkbox
                color={theme.colors.primary}
                status={wantsSand ? "checked" : "unchecked"}
                onPress={onPressToChangeSand}
              />
              <Text style={[styles.sandInputText]}>
                {t("TextFirstTimeHeaderSandInputQuestion")}
                {animals.map((animal, index) => {
                  if (index === animals.length - 1 && index !== 0) {
                    return (
                      <Text key={"name_of_animal_list" + index}>
                        {" "}
                        {t("TextAnd")}{" "}
                        {animal.sex === "female" ? t("TextA") : t("TextO")}
                        {t("TextO") == "" || t("TextA") == "" ? "" : " "}
                        <Text style={styles.animalName}>{animal.name}</Text>
                      </Text>
                    );
                  } else {
                    return (
                      <Text key={"name_of_animal_list" + index}>
                        {index !== 0 ? ", " : " "}
                        {animal.sex === "female" ? t("TextA") : t("TextO")}
                        {t("TextO") == "" || t("TextA") == "" ? "" : " "}
                        {<Text style={styles.animalName}>{animal.name}</Text>}
                      </Text>
                    );
                  }
                })}
                ?
              </Text>
            </View>
          </>
        )}
      </View>
      <Products />
      <View style={styles.infoAddOnContainer}>
        <Icon
          style={styles.checkpointInfoIcon}
          name="phone"
          size={moderateScale(40)}
        />
        <Text style={styles.infoAddOn} variant="labelMedium">
          {t("TextBillingSpeakWithUs2")}
        </Text>
      </View>
      {/* <Pressable
        style={styles.sectionVetOpinion}
        onPress={
          Platform.OS === "web"
            ? async () => {
                // await Analytics.logEvent(`press_FAB_callus_button`);
                window.open(
                  "https://api.whatsapp.com/send/?phone=%2B351913847159",
                  "_blank",
                );
              }
            : async () => {
                // await Analytics.logEvent(`press_FAB_callus_button`);
                goToLink("whatsapp://send?phone=+351913847159");
              }
        }
      >
        <Icon
          style={styles.sectionVetOpinionIcon}
          name={"shield-plus"}
          size={moderateScale(25)}
        />
        <Text style={styles.sectionVetOpinionText} variant="labelMedium">
          {t("TextGetVetOpinion")}
        </Text>
      </Pressable> */}
    </View>
  );
};

export default memo(SummaryStage);
